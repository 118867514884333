import { IRootState } from "./state";

export interface IRootGetters {
  form(state: IRootState): any;
}

export const RootGetters: IRootGetters = {
  form(state: IRootState): any {
    return state.form;
  },
};
