import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import { createStore } from "vuex";
import RootModule from "./modules/root";
import auth from "./modules/auth";
import api from "@/api";
import database from "./db";
import VuexORMSearch from "@vuex-orm/plugin-search";

VuexORM.use(VuexORMSearch);
VuexORM.use(VuexORMAxios, { axios: api });

export default createStore({
  ...RootModule,

  modules: {
    auth,
  },

  plugins: [VuexORM.install(database)],
});
