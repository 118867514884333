import { Model, Fields } from "@vuex-orm/core";

export enum UserRole {}

export default class User extends Model {
  static entity = "users";
  static primaryKey = "id";

  //metadata: Record<string, string>;

  static fields(): Fields {
    return {
      id: this.string(""),
      username: this.string(""),
      confirmed: this.boolean(true),
      blocked: this.boolean(false),
      email: this.string(""),
      role: this.attr(""),
      metadata: this.attr(""),
      createdAt: this.string(null),
      updatedAt: this.string(null),
    };
  }

  static apiConfig = {
    actions: {
      fetch(this: any): any {
        return this.get("/users");
      },

      create(this: any, data: Record<string, unknown>): any {
        return this.post("/users", data);
      },

      update(this: any, id: string, data: Record<string, unknown>): any {
        return this.put(`/users/${id}`, data);
      },

      remove(this: any, id: string) {
        return this.delete(`/users/${id}`);
      },
    },
  };

  /* get roleId(): string {
    return this.metadata ? this.metadata[this.metadata.type] : "";
  } */
}
