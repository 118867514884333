import { Model, Fields } from "@vuex-orm/core";
import User from "./User";

export default class Recrutor extends Model {
  static entity = "recrutors";
  static primaryKey = "id";

  static fields(): Fields {
    return {
      id: this.string(""),
      profilImage: this.attr(""),
      firstname: this.string(""),
      lastname: this.string(""),
      email: this.string(""),
      telephone: this.string("phone"),
      countryCode: this.string(""),
      countryIso: this.string(""),
      country: this.string(""),
      nationality: this.string(""),
      url: this.string(""),
      user: this.attr(""),
      createdAt: this.string(null),
      updatedAt: this.string(null),
      favoris: this.attr(null),
      getInTouch: this.attr(null),

      userData: this.belongsTo(User, "user"),
    };
  }

  static apiConfig = {
    actions: {
      fetch(this: any): any {
        return this.get("/recrutors");
      },

      fetchById(this: any, id: string): any {
        return this.get(`/recrutors/${id}`);
      },

      create(this: any, data: Record<string, unknown>): any {
        return this.post("/recrutors", data);
      },

      update(this: any, id: string, data: Record<string, unknown>): any {
        return this.put(`/recrutors/${id}`, data);
      },

      remove(this: any, id: string) {
        return this.delete(`/recrutors/${id}`);
      },
    },
  };
}
