export interface IAuthState {
  accessToken: string | null;
  userId: string | null;
}

export const AuthState = (): IAuthState => {
  return {
    accessToken: null,
    userId: null,
  };
};
