import { Model, Fields } from "@vuex-orm/core";
import User from "./User";

export default class Admin extends Model {
  static entity = "admins";
  static primaryKey = "id";

  static fields(): Fields {
    return {
      id: this.string(""),
      profilImage: this.attr(""),
      firstname: this.string(""),
      lastname: this.string(""),
      position: this.string(""),
      email: this.string(""),
      telephone: this.string("phone"),
      countryCode: this.string(""),
      countryIso: this.string(""),
      country: this.string(""),
      nationality: this.string(""),
      user: this.attr(""),
      createdAt: this.string(null),
      updatedAt: this.string(null),

      userData: this.belongsTo(User, "user"),
    };
  }
}
