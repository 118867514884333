import { Model, Fields } from "@vuex-orm/core";
import User from "./User";
import { Request } from "@vuex-orm/plugin-axios";

export default class Talent extends Model {
  static entity = "talents";
  static primaryKey = "id";

  static fields(): Fields {
    return {
      id: this.string(""),
      profilImage: this.attr(""),
      firstname: this.string(""),
      lastname: this.string(""),
      position: this.string(""),
      email: this.string(""),
      telephone: this.string("phone"),
      countryCode: this.string(""),
      countryIso: this.string(""),
      country: this.string(""),
      nationality: this.string(""),
      height: this.attr(""),
      bio: this.string(""),
      birthdate: this.attr(""),
      user: this.attr(""),
      topTalent: this.attr(""),
      createdAt: this.string(null),
      updatedAt: this.string(null),
      numberOfVideos: this.number(null),

      userData: this.belongsTo(User, "user"),
    };
  }

  static apiConfig = {
    actions: {
      fetch(this: Request) {
        return this.get("/talents");
      },

      fetchById(this: Request, id: string) {
        return this.get(`/talents/${id}`);
      },

      fetchTalentVideos(this: Request, id: string) {
        return this.get(`/talents/${id}/videos`);
      },

      uploadVideo(this: Request, id: string, data: Record<string, unknown>) {
        return this.post(`/talents/${id}/videos`, data);
      },

      create(this: Request, data: Record<string, unknown>) {
        return this.post("/talents", data);
      },

      update(this: Request, id: string, data: Record<string, unknown>) {
        return this.put(`/talents/${id}`, data);
      },

      remove(this: Request, id: string) {
        return this.delete(`/talents/${id}`);
      },
    },
  };
}
