import { Model, Fields } from "@vuex-orm/core";

export default class Contact extends Model {
  static entity = "contacts";
  static primaryKey = "id";

  static fields(): Fields {
    return {
      id: this.string(""),
      recrutorTelephone: this.attr(""),
      recrutorEmail: this.string(""),
      subject: this.string(""),
      message: this.string(""),
      view: this.attr(""),
      recrutor: this.attr(""),
      talent: this.attr(""),
      createdAt: this.attr(""),
      updatedAt: this.attr(""),
    };
  }
}
