import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import SvgIcon from "@/components/SvgIcon.vue";
import VuePlyr from "@hulkapps/vue-plyr";
import "@hulkapps/vue-plyr/dist/vue-plyr.css";

store.dispatch("auth/check").then((response) => {
  if (response && response.isSuccessful) {
    console.log("enter in dashboard");
  }
  const app = createApp(App);
  app.component("SvgIcon", SvgIcon);

  app
    .use(store)
    .use(router)
    .use(VuePlyr, {
      plyr: {},
    })
    .mount("#app");
});
