export interface IRootState {
  count: number;
  form: any;
}

export const RootState = (): IRootState => {
  return {
    count: 0,
    form: {},
  };
};
