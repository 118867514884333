import store from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("../components/Layouts/AuthBody.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("../views/auth/Login.vue"),
      },
      {
        path: "password-forgot",
        name: "password-forgot",
        component: () => import("../views/auth/ForgetPwd.vue"),
      },
    ],
  },
  {
    path: "/app",
    meta: {
      auth: true,
    },
    component: () => import("../components/Layouts/DefaultBody.vue"),
    children: [
      {
        path: "/app/dashboard",
        meta: {
          icon: "home",
          title: "Dashboard",
        },
        component: () => import("../views/Dashboard/DashIndex.vue"),
        children: [
          {
            path: "",
            name: "dashboard",
            component: () => import("../views/Dashboard/DashStats.vue"),
          },
          {
            path: "talent/:id",
            name: "talentDetail",
            component: () => import("../views/Dashboard/TalentDetail.vue"),
            props: true,
          },
          {
            path: "recruiter/:id",
            name: "recruiterDetail",
            component: () => import("../views/Dashboard/RecruiterDetail.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/app/messages",
        name: "messages",
        meta: {
          icon: "messages",
          title: "Messages from recruiters",
        },
        component: () => import("../views/Dashboard/MessagesView.vue"),
      },
      {
        path: "/app/settings",
        name: "settings",
        meta: {
          icon: "settings",
          title: "Settings",
        },
        component: () => import("../views/Dashboard/SettingsView.vue"),
      },
      {
        path: "/app/password-reset",
        name: "pwdReset",
        component: () => import("../views/auth/PasswordReset.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authenticated = store.getters["auth/authenticated"];

  const routeWithAuth = to.matched.find((r) => r.meta && r.meta.auth);

  if (routeWithAuth) {
    if (!authenticated) {
      return next({ name: "login", query: { redirect: to.fullPath } });
    } else next();
  } else next();
});

export default router;
