import { Database } from "@vuex-orm/core";
import Admin from "./models/Admin";
import Contact from "./models/Contact";
import Recrutor from "./models/Recrutor";
import Talent from "./models/Talent";
import User from "./models/User";
import Video from "./models/Video";

const database = new Database();

// Register Models.
database.register(Admin);
database.register(User);
database.register(Talent);
database.register(Recrutor);
database.register(Video);
database.register(Contact);

export default database;
