import { ActionContext } from "vuex";
import { IRootState } from "./state";

import api, { ApiResponse } from "@/api";
import { Types } from "./mutations";

export interface IRootActions {
  fetchCounts(context: ActionContext<IRootState, IRootState>): number;
}

export const RootActions: IRootActions = {
  fetchCounts({ commit }) {
    /* return api.countBeneficiaries().then((response: ApiResponse) => {
      if (response.isSuccessful) {
        commit(Types.ADD_BENEFICIARY_COUNTS, response.data)
      }

      return response
    }) */
    return 0;
  },
};
