import { IAuthState } from "./state";
import User from "../../models/User";

export interface IAuthGetters {
  authenticated(state: IAuthState): boolean;
  userId(state: IAuthState): string;
  user(state: IAuthState, getters: any, rootState: any, rootGetters: any): User;
  //userRole(state: IAuthState, getters: any): any;
}

export const AuthGetters: IAuthGetters = {
  authenticated(state: IAuthState): boolean {
    return !!state.accessToken;
  },

  userId(state: IAuthState): string {
    return state.userId as string;
  },

  user(state: IAuthState, _, __, rootGetters): User {
    return rootGetters["entities/users/find"](state.userId);
  },

  /* userRole(state: IAuthState, getters: any): any {
    return getters.user ? (getters.user as User).role["name"] : "";
  }, */
};
