import Axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  AxiosInstance,
} from "axios";
import store from "@/store";
/* import fileDownload from 'js-file-download'
import moment from 'moment'
 */
export interface ApiResponse {
  headers: Record<string, string>;
  status: number;
  data: any; // eslint-disable-line
  isOk: boolean;
  isSuccessful: boolean;
  isNotFound: boolean;
  isConflict: boolean;
  isClientError: boolean;
  isBadRequest: boolean;
}

export interface Api extends AxiosInstance {
  makeRequest(config: AxiosRequestConfig): Promise<ApiResponse>;
  setAccessToken(token: string | null): void;
  authenticate(options: {
    username: string;
    password: string;
  }): Promise<ApiResponse>;
  register(data: any): Promise<ApiResponse>;
  verify(data: any): Promise<ApiResponse>;
  confirmCode(data: any, id: string): Promise<ApiResponse>;
  confirmMail(data: any): Promise<ApiResponse>;
  getUserInfos(): Promise<ApiResponse>;

  requestPasswordReset(data: any): Promise<ApiResponse>;
  verifyPasswordResetToken(
    code: string /* , email: string */
  ): Promise<ApiResponse>;
  resetPassword(data: any): Promise<ApiResponse>;
}

const api: AxiosInstance = Axios.create({
  baseURL: "/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const wrapResponse = (response: AxiosResponse): ApiResponse => ({
  status: response.status,
  data: response.data,
  isOk: response.status === 200,
  isBadRequest: response.status === 400,
  isNotFound: response.status === 404,
  isConflict: response.status === 409,
  isSuccessful: response.status >= 200 && response.status < 300,
  isClientError: response.status >= 400 && response.status < 500,
  headers: response.headers,
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    console.log("interceptors call");
    if (
      error.response &&
      error.response.status === 401 &&
      !window.location.origin
    ) {
      console.log("-->", error, error.response);
      store.dispatch("auth/logout").then(() => {
        window.location.href =
          "/?reason=session-expired&redirect=" + window.location.pathname;
      });
    } else {
      return Promise.reject(error);
    }
  }
);

const request = (options: AxiosRequestConfig) =>
  api
    .request(options)
    .then((response: AxiosResponse) => wrapResponse(response))
    .catch((error: AxiosError) => {
      if (error.response) {
        return wrapResponse(error.response);
      }

      throw error;
    });

/**
 * Set auth access token
 * @param token
 */
(api as Api).setAccessToken = (token: string) =>
  (api.defaults.headers.common["Authorization"] = `Bearer ${token}`);

/** */
(api as Api).makeRequest = request;

/**
 * Authenticate user
 * @param credentials
 */
(api as Api).authenticate = (credentials: {
  username: string;
  password: string;
}) =>
  request({
    method: "POST",
    url: "admin/auth/login",
    data: {
      ...credentials,
    },
  });

/**
 * verify user
 * @param credentials
 */
(api as Api).verify = (data: { username: string }) =>
  request({
    method: "POST",
    url: "admin/auth/verify",
    data,
  });

/**
 * confirm code
 * @param credentials
 */
(api as Api).confirmCode = (data: { code: string }, id: string) =>
  request({
    method: "POST",
    url: `admin/auth/${id}/confirme-register`,
    data,
  });

/**
 * confirm mail
 * @param credentials
 */
(api as Api).confirmMail = (data: {
  email: string;
  user_id: string;
  verify_token: string;
  verify_code: string;
}) =>
  request({
    method: "POST",
    url: `admin/auth/${data.user_id}/confirme-register?email=${data.email}&user_id=${data.user_id}&verify_token=${data.verify_token}&verify_code=${data.verify_code}`,
    data: { code: data.verify_code },
  });

/**
 * Register user
 * @param credentials
 */
(api as Api).register = (data) =>
  request({
    method: "POST",
    url: "admin/auth/register",
    data,
  });

/**
 * Get authenticiated user infos
 */
(api as Api).getUserInfos = () =>
  request({
    method: "GET",
    url: "admin/auth/me",
  });

/**
 * Request password reset token
 */
(api as Api).requestPasswordReset = (data: any) =>
  request({
    method: "POST",
    url: "admin/auth/forgot-password",
    data,
  });

/**
 *
 */
(api as Api).verifyPasswordResetToken = (code /* email */) =>
  request({
    method: "GET",
    url: "admin/auth/check-password-token",
    params: {
      code,
      //email,
    },
  });

/**
 *
 */
(api as Api).resetPassword = (data: any) =>
  request({
    url: "admin/auth/reset-password",
    method: "POST",
    data,
  });

export default api as Api;
