import { Model, Fields } from "@vuex-orm/core";
import Talent from "./Talent";

export default class Video extends Model {
  static entity = "videos";
  static primaryKey = "_id";

  static fields(): Fields {
    return {
      _id: this.attr("_id"),
      title: this.string(""),
      description: this.string(""),
      file: this.attr(""),
      poster: this.attr(""),
      owner: this.attr(""),
      views: this.attr(""),
      createdAt: this.attr(""),
      updatedAt: this.attr(""),

      talent: this.belongsTo(Talent, "owner"),
    };
  }

  static apiConfig = {
    actions: {
      fetch(this: any): any {
        return this.get("/videos");
      },

      create(this: any, data: Record<string, string>): any {
        return this.post("/videos", data);
      },

      update(this: any, id: string, data: Record<string, string>): any {
        return this.put(`/videos/${id}`, data);
      },

      remove(this: any, id: string) {
        return this.delete(`/videos/${id}`);
      },
    },
  };
}
