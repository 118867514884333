import { IRootState } from "./state";

export enum Types {
  ADD_COUNTS = "ADD_COUNTS",
  ADD_FORM = "ADD_FORM",
}

export interface IRootMutations {
  [Types.ADD_FORM](state: IRootState, data: any): void;
}

export const RootMutations: IRootMutations = {
  [Types.ADD_FORM](state, data) {
    state.form = data;
  },
};
