import { RootState, IRootState } from "./state";
import { Module } from "vuex";
import { RootGetters } from "./getters";
import { RootActions } from "./actions";
import { RootMutations } from "./mutations";

export { IRootState };

const RootModule: Module<IRootState, IRootState> = {
  state: {
    ...RootState(),
  },

  getters: {
    ...RootGetters,
  },

  actions: {
    ...RootActions,
  },

  mutations: {
    ...RootMutations,
  },
};

export default RootModule;
