import api, { ApiResponse } from "@/api";
import { ActionContext } from "vuex";
import { IAuthState } from "./state";
import { Types } from "./mutations";
import { AUTH_ACCESS_TOKEN_KEY } from "@/constants";
import User from "../../models/User";
import { IRootState } from "../root";

export interface ILoginCredentials {
  username: string;
  password: string;
}
export interface IVerifyUser {
  username: string;
}
export interface IConfirmUser {
  id: string;
  code: string;
}

export interface IAuthActions {
  check(context: ActionContext<IAuthState, IRootState>): any;

  login(
    context: ActionContext<IAuthState, IRootState>,
    credentials: ILoginCredentials
  ): Promise<ApiResponse>;

  verify(
    context: ActionContext<IAuthState, IRootState>,
    credentials: ILoginCredentials
  ): Promise<ApiResponse>;

  confirmCode(
    context: ActionContext<IAuthState, IRootState>,
    data: IConfirmUser
  ): Promise<ApiResponse>;

  confirmMail(
    context: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse>;

  register(
    context: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse>;

  requestPasswordReset(
    context: ActionContext<IAuthState, IRootState>,
    credentials: ILoginCredentials
  ): Promise<ApiResponse>;

  verifyPasswordResetToken(
    context: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse>;

  resetPassword(
    context: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse>;

  logout(context: ActionContext<IAuthState, IRootState>): void;
}

export const AuthActions: IAuthActions = {
  check({ commit, dispatch }: ActionContext<IAuthState, IRootState>) {
    const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY);

    if (accessToken) {
      commit(Types.ACCESS_TOKEN, accessToken);
      api.setAccessToken(accessToken);

      return api.getUserInfos().then((response: ApiResponse) => {
        if (response.isSuccessful) {
          const data = response.data;
          const user = data.user;
          commit(Types.USER_ID, user.id);
          commit(Types.ACCESS_TOKEN, accessToken);

          User.dispatch("insertOrUpdate", { data: user });
        } else {
          dispatch("logout");
        }

        return response;
      });
    }
  },

  login(
    { commit }: ActionContext<IAuthState, IRootState>,
    { username, password }: ILoginCredentials
  ): Promise<ApiResponse> {
    return api
      .authenticate({ username, password })
      .then((response: ApiResponse) => {
        if (response.isSuccessful) {
          const { token, user } = response.data;

          commit(Types.USER_ID, user.id);
          commit(Types.ACCESS_TOKEN, token);
          localStorage.setItem("user", JSON.stringify(user));
          User.dispatch("insertOrUpdate", { data: user });

          api.setAccessToken(token);
          sessionStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
        }

        return response;
      });
  },

  verify(
    { commit }: ActionContext<IAuthState, IRootState>,
    { username }: IVerifyUser
  ): Promise<ApiResponse> {
    return api.verify({ username }).then((response: ApiResponse) => {
      return response;
    });
  },

  confirmCode(
    { commit }: ActionContext<IAuthState, IRootState>,
    { code, id }: IConfirmUser
  ): Promise<ApiResponse> {
    return api.confirmCode({ code }, id).then((response: ApiResponse) => {
      return response;
    });
  },

  confirmMail(
    { commit }: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse> {
    return api.confirmMail(data).then((response: ApiResponse) => {
      return response;
    });
  },

  register(
    { commit }: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse> {
    return api.register(data).then((response: ApiResponse) => {
      return response;
    });
  },

  requestPasswordReset(
    { commit }: ActionContext<IAuthState, IRootState>,
    { username }: IVerifyUser
  ): Promise<ApiResponse> {
    return api
      .requestPasswordReset({ username })
      .then((response: ApiResponse) => {
        return response;
      });
  },
  verifyPasswordResetToken(
    { commit }: ActionContext<IAuthState, IRootState>,
    code: string
  ): Promise<ApiResponse> {
    return api.verifyPasswordResetToken(code).then((response: ApiResponse) => {
      return response;
    });
  },
  resetPassword(
    { commit }: ActionContext<IAuthState, IRootState>,
    data: any
  ): Promise<ApiResponse> {
    return api.resetPassword(data).then((response: ApiResponse) => {
      return response;
    });
  },

  logout({ commit }: ActionContext<IAuthState, IRootState>) {
    commit(Types.ACCESS_TOKEN, null);
    commit(Types.USER_ID, null);
    api.setAccessToken(null);
    localStorage.removeItem("user");
    sessionStorage.clear();
  },
};
